import { Routes, Route, useNavigate } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";
import { useAppDispatch } from "./hooks/store";
import { useEffect } from "react";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import { resetAuth, setCredentials } from "./reducers/authSlice";

const Routing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window.location.pathname === "/") {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate("/dashboard", { replace: true });
    } else if (!token && window?.location?.pathname === "/") {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    } else if (token) {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate(window?.location?.pathname);
    } else {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manageusers" element={<Pages.ManageUsers />} />
      <Route path="/manageusers/userdetails/:id" element={<UserDetails />} />
      <Route path="/manageusers/usersform/:id" element={<UsersForm />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route path="/settings" element={<Pages.Settings />} />
      <Route path="/manage-banner" element={<Pages.ManageBanner />} />
      <Route path="/manage-banner/add" element={<Pages.AddBanner />} />
      <Route path="/manage-banner/edit/:id" element={<Pages.AddBanner />} />
      <Route
        path="/manage-subscription"
        element={<Pages.ManageSubscription />}
      />
      <Route
        path="/manage-subscription/add"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route
        path="/manage-subscription/edit/:id"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/manage-notifications/details/:id"
        element={<Pages.NotificationDetails />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/edit/:id"
        element={<Pages.AddCategories />}
      />
      <Route
        path="/manage-categories/details/:id"
        element={<Pages.SubCategories />}
      />
      <Route path="/manage-emails" element={<Pages.ManageEmail />} />
      <Route
        path="/set-points-criteria"
        element={<Pages.SetPointsCriteria />}
      />
      <Route path="/manage-round-up" element={<Pages.ManageRoundUp />} />
      <Route
        path="/manage-savings-limit"
        element={<Pages.ManageSavingsLimit />}
      />
      <Route path="/permission-denied" element={<Pages.PermissionDenied />} />
    </Routes>
  );
};

export default Routing;
