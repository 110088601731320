import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { useLazyGetCmsQuery, useUpdateCmsMutation } from "../../services/cms";
import { useFormik } from "formik";
import { Loader, showError, showToast } from "../../constants";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Delete } from "@mui/icons-material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [tips, setTips] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const [tipValue, setTipValue] = useState<string>("");
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [programAgreement, setProgramAgreement] = useState<string>("");
  const [autoDebitAuthorization, setAutoDebitAuthorization] =
    useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [value, setValue] = useState(0);
  const [cmsId, setCmsId] = useState("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [cmsDetails, getCmaDetailData] = useLazyGetCmsQuery();
  const [updateCms, { isLoading }] = useUpdateCmsMutation();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage CMS"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const handleAdd = (e: React.MouseEvent) => {
    e.preventDefault();
    if (tipValue.trim() === "") {
      setError("This field is required.");
      return;
    }

    if (tipValue.trim().length < 3) {
      setError("Tip must be at least 3 characters long.");
      return;
    }
    setTips([...tips, tipValue]);
    setTipValue("");
    setError(null);
  };

  const handleDelete = (index: number) => {
    let arr1 = [];
    arr1.push(...tips.slice(0, index), ...tips.slice(index + 1));
    setTips([...arr1]);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body = {
        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        terms: termsAndConditions,
        privacy: privacyPolicy,
        agreement: programAgreement,
        autoDebitAuthorization: autoDebitAuthorization,
        tips: tips,
        appkey: Date.now(),
      };
      console.log(body, "cmsBody");
      if (tips?.length === 0) {
        showError("Please enter tips");
        formik.setSubmitting(false);
        return;
      }
      try {
        const response = await updateCms({ body, cms_id: cmsId }).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "CMS updated successfully!");
        }
      } catch (error: any) {
        console.log(error, "error");
        showError(error?.data?.message || "");
      }
    },
  });

  const getCmsDetail = async () => {
    try {
      const res = await cmsDetails({}).unwrap();
      if (res?.statusCode === 200 && res?.data?.length) {
        formik.setFieldValue("email", res?.data[0]?.email);
        formik.setFieldValue("phone", res?.data[0]?.phone);
        setPrivacyPolicy(res?.data[0]?.privacy || " ");
        setProgramAgreement(res?.data[0]?.agreement || " ");
        setTermsAndConditions(res?.data[0]?.terms || " ");
        setAutoDebitAuthorization(res?.data[0]?.autoDebitAuthorization || "");
        setPhoneCode(res?.data[0]?.countryCode || "");
        setCmsId(res?.data[0]?._id || "");
        setTips(res?.data[0]?.tips || []);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCmsDetail();
  }, []);
  return (
    <MainContainer>
      <Loader isLoad={isLoading || getCmaDetailData?.isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage CMS</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Privacy Policy" {...a11yProps(1)} />
              <Tab label="Terms and Conditions" {...a11yProps(2)} />
              <Tab label="Program Agreement" {...a11yProps(3)} />
              <Tab label="Auto Debit Authorization" {...a11yProps(4)} />
              <Tab label="Tips and Guidance" {...a11yProps(5)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 40 }}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder="Phone Number"
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                <div className="form_btn">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                <div className="form_btn">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={programAgreement}
                  setContent={setProgramAgreement}
                />
                <div className="form_btn">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={autoDebitAuthorization}
                  setContent={setAutoDebitAuthorization}
                />
                <div className="form_btn">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ul>
                      {tips?.length
                        ? tips?.map((item, i) => {
                            return (
                              <li key={i} className="table_actions tips">
                                <p>{item}</p>
                                <IconButton onClick={() => handleDelete(i)}>
                                  <Delete />
                                </IconButton>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={12}>
                    <Typography className="custom_label">Add Tip</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      name="tip"
                      variant="outlined"
                      fullWidth
                      placeholder="Add Tip"
                      id="tip"
                      value={tipValue}
                      onChange={(e) => {
                        setTipValue(e.target.value);
                        setError(null); // Clear error message when user types
                      }}
                      error={!!error}
                      helperText={error}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={3}
                    sm={2}
                    xs={12}
                    className="table_actions"
                    style={{
                      paddingTop: "43px",
                    }}
                  >
                    <IconButton onClick={handleAdd}>
                      <AddBoxIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
