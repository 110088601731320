import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { useAddNotificationMutation } from "../../services/notification";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showError, showToast } from "../../constants";
import { ContentState, convertToRaw } from "draft-js";
import {
  useLazyGetAllUsersQuery,
  useLazyGetUsersQuery,
} from "../../services/customers";
import { AllUser } from "../../types/General";
import { isString } from "../../utils/validations";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import EditText from "../../components/EditText";

const AddNotification = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState<AllUser[]>([]);
  const [getAllUsersList] = useLazyGetUsersQuery();
  const [addNotification] = useAddNotificationMutation();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [error, setError] = useState<boolean>(false);

  const [notificationMessage, setNotificationMessage] = useState("");
  const [userNames, setUserNames] = useState<string[]>();
  const [checked, setChecked] = useState<boolean>(false);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  console.log(notificationMessage, "notificationMessage");
  console.log(error, "error");

  const userPermissions = userData?.permissions?.length
    ? JSON.parse(`${userData?.permissions}`)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Notifications"
      );
      if (idx > -1) {
        const manageAddEditPermission = permission[idx];
        if (manageAddEditPermission && !manageAddEditPermission?.isEdit) {
          navigate("/permission-denied", { replace: true });
        } else {
          setHidePermission(manageAddEditPermission);
        }
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleReceiverChange = (
    event: SelectChangeEvent<typeof selectedUsers>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(
      // Ensure the value is not null or undefined
      typeof value === "string" ? [value] : value
    );
    console.log(value, "value");
  };
  const getUsers = async () => {
    try {
      const response = await getAllUsersList({}).unwrap();
      if (response?.statusCode === 200) {
        setAllUsers(response?.data?.data || []);
      } else {
        setAllUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const label = { inputProps: { "aria-label": "controlled" } };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      // message: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 3 characters are required"),

      // message: Yup.string()
      //   .required("This field is required")
      //   .min(3, "Minimum 3 characters are required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let hasError = false;

      if (
        !notificationMessage.trim() ||
        notificationMessage.trim().length < 9
      ) {
        setError(true);
        hasError = true;
      } else {
        setError(false);
      }

      if (hasError) {
        formik.setSubmitting(false);
        return;
      }

      const body = {
        title: formik.values.title,
        message: notificationMessage,
        users: selectedUsers,
        appkey: Date.now(),
      };
      const allUsers_body = {
        title: formik.values.title,
        message: notificationMessage,
        appkey: Date.now(),
      };
      console.log(checked ? allUsers_body : body, "body");

      try {
        const response = await addNotification({
          body: checked ? allUsers_body : body,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "");
          navigate("/manage-notifications");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });
  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Notification</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik?.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      id="title"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Message
                    </Typography>

                    <EditText
                      content={notificationMessage}
                      setContent={setNotificationMessage}
                    />
                    {error &&
                    (!notificationMessage.trim() ||
                      notificationMessage.trim()?.length < 9) ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12} />

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Receiver
                    </Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedUsers}
                        disabled={checked}
                        multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleReceiverChange}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Select</em>;
                          }
                          // You can customize the render value based on your requirements.
                          const selectedUserNames = selected.map((userId) => {
                            const user = allUsers.find(
                              (user) => user._id === userId
                            );
                            return user
                              ? `${user.firstName} ${user.lastName}`
                              : "";
                          });
                          return selectedUserNames.join(", ");
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {allUsers.map((user) => (
                          <MenuItem key={user?._id} value={user?._id}>
                            {user?.firstName} {user?.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...label}
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Send to All"
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
